<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card class="ma-auto">
        <v-card-title
          ><h2 class="text-h5 mt-3 ml-3 mb-n4">Add Account</h2></v-card-title
        >
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-text-field
                label="Customer Name"
                prepend-icon="mdi-account-circle-outline"
                v-model="form.customer_name"
              ></v-text-field>
              <v-text-field
                label="Customer Contact Number"
                prepend-icon="mdi-phone-outline"
                v-model="form.customer_contact_no"
              ></v-text-field>
              <v-text-field
                label="Customer TIN"
                prepend-icon="mdi-form-textbox-password"
                v-model="form.tin"
              ></v-text-field>
              <v-text-field
                label="Other Contact Platform"
                prepend-icon="mdi-facebook-messenger"
                prefix="messenger via -"
                v-model="form.other_contact_platform"
              ></v-text-field>
              <address-picker
                Heading="Customer Address"
                @extract_address_value="extract"
              />
              <p class="text-caption">Where did you find us?</p>
              <v-select
                :items="where_did_you_find_us"
                label="Where did you find us?"
                prepend-icon="mdi-cloud-question-outline"
                v-model="form.where_did_you_find_us"
              ></v-select>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" text color="primary" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="reset" text color="warning">Reset</v-btn>
          <v-btn @click="dialog = false" text color="grey">Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">addCustomerModal.vue</p>
        </v-card-actions></v-card
      ></v-dialog
    >
  </div>
</template>
<script>
import { mapActions } from "vuex";
import AddressPicker from "@/components/addressPicker.vue";
export default {
  components: { AddressPicker },
  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        customer_name: "",
        customer_contact_no: "",
        other_contact_platform: "",
        customer_municipality: "",
        customer_barangay: "",
        customer_purok: "",
        where_did_you_find_us: "",
        tin: "000-000-000",
      },
      where_did_you_find_us: [
        "Brochuers and Flyers",
        "Facebook",
        "Radio",
        "Referal",
      ],
    };
  },
  methods: {
    ...mapActions({
      add_customer: "customer/add_customer",
      set_notification: "notification/set_notification",
    }),

    extract(data) {
      this.form.customer_municipality = data[0];
      this.form.customer_barangay = data[1];
      this.form.customer_purok = data[2];
      console.log(this.form);
    },
    async submit() {
      this.loading = true;
      if (
        this.form.customer_name == "" ||
        this.form.customer_municipality == "" ||
        this.form.customer_contact_no == ""
      ) {
        this.loading = false;
        return this.set_notification({
          message: "Please check all fields",
          type: "error",
        });
      }

      await this.add_customer(this.form).catch((e) => {
        this.loading = false;
        return this.set_notification({
          message: e,
          type: "error",
        });
      });
      this.loading = false;
      return this.set_notification({
        message: "You have successfully inserted a new customer!",
        type: "success",
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
