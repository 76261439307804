<template>
  <div>
    <h3>{{ Heading }}</h3>
    <v-select
      label="Municipality"
      :items="municipalities_arr"
      item-text="city_name"
      v-model="municipality"
      @change="get_barangays"
    ></v-select>
    <v-select
      :disabled="!municipality"
      label="Barangay"
      :items="barangays_arr"
      item-text="brgy_name"
      v-model="barangay"
      @input="purok = null"
    ></v-select>
    <v-row>
      <v-col cols="1"
        ><v-checkbox v-model="enable_purok" Label="Enable Purok"></v-checkbox
      ></v-col>
      <v-col cols="5">
        <v-text-field
          :disabled="!enable_purok"
          type="text"
          label="Purok"
          v-model="purok"
          class="rounded-xl"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn
          :disabled="!municipality"
          small
          @click="extract_address_value"
          color=""
          >{{ button_text }}</v-btn
        ></v-col
      >
    </v-row>
  </div>
</template>

<script>
import barangays from "@/json/barangays.json";
import cities from "@/json/cities.json";
export default {
  props: ["Heading", "Fields"],
  data() {
    return {
      enable_purok: false,
      municipality: null,
      barangay: null,
      purok: null,
      button_text: "save address",
      button_text2: "get brgys",
      barangays_arr: ["Please click GET BRGYS Button"],
      municipalities_arr: [],
    };
  },
  methods: {
    get_cities() {
      var city = cities.filter((c) => {
        return c.province_code == "1668";
      });
      this.municipalities_arr = city;
    },

    extract_city_code(city_name) {
      // extract city code from city name
      var city = cities.filter((c) => {
        return c.city_name == city_name;
      });
      var c = city[0];
      return c.city_code;
    },

    get_barangays() {
      var city_code = this.extract_city_code(this.municipality);

      var brgys = barangays.filter((b) => {
        return b.city_code == city_code;
      });
      this.barangays_arr = brgys;
      this.button_text2 = "Saved";
      setTimeout(() => {
        this.button_text2 = "get brgys";
      }, 3000);
    },
    extract_address_value() {
      var address_arr = [this.municipality, this.barangay, this.purok];
      this.button_text = "Saved";
      setTimeout(() => {
        this.button_text = "Save address";
      }, 3000);

      this.$emit("extract_address_value", address_arr);
    },
  },
  mounted() {
    this.get_cities();
  },
};
</script>

<style></style>
